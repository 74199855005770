import {
  AlertConstants,
  AsSelectPlaceholders,
  Button,
  DateInput,
  Email,
  formatDate,
  Input,
  Select,
  SubSection,
  useAlerts,
  useBreakpoints,
  useLoading,
  ValueOpt,
} from "best-common-react";
import { format, parse, isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDropdowns } from "../../contexts/DropdownsContext";
import { Country, CountryIds } from "../../types/Metadata";
import { RegistrationCutoffDTO, RegistrationDTO } from "../../types/Registration";
import AccountAlreadyExistsModal from "./error-modals/AccountAlreadyExistsModal";
import BackButton from "./display/BackButton";
import DuplicateAccountDetectedModal from "./error-modals/DuplicateAccountDetectedModal";
import SelfRegisteringTrainerModal from "./error-modals/SelfRegisteringTrainerModal";
import CountryCheckModal from "./error-modals/CountryCheckModal";
import { DATE_INPUT_MIN_DATE, dateFormats } from "../../util/ProfileUtils";
import { register } from "../../api/auth";
import { Auth0Error } from "auth0-js";
import { useAuthentication } from "../../contexts/AuthenticationContext";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import RegistrationPeriodClosedModal from "./error-modals/RegistrationPeriodClosedModal";
import UnauthenticatedNavigation from "../navigation/UnauthenticatedNavigation";
import {
  checkRegistrationPeriod,
  getRegistrationCutoffDates,
  getRegistrationInformation,
  registerProspect,
  validateRegistration,
} from "../../api/UnauthenticatedClient";
import { LanguageIds } from "../../constants/LanguageConstants";
import ProspectPasswordInput from "./ProspectPasswordInput";
import ProspectAlreadyRegisteredModal from "./error-modals/ProspectAlreadyRegisteredModal";
import InvalidRegistrationCountryWarningModal from "./error-modals/InvalidRegistrationCountryWarningModal";
import ExpiredInvitationModal from "./error-modals/ExpiredInvitationModal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ContainerProps = {
  isMobile: boolean;
};

const Body = styled.div<ContainerProps>`
  width: ${(props) => (props.isMobile ? "100%" : "48rem")};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const WarningText = styled.span`
  color: red;
`;

type RegistrationForm = {
  onBack: () => void;
  invitationLinkId?: string;
  isTPP: boolean;
};

const RegistrationForm: React.FC<RegistrationForm> = ({ onBack, invitationLinkId, isTPP }) => {
  const { setAuthenticated } = useAuthentication();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { setLoading } = useLoading();
  const { addAlert } = useAlerts();
  const { t, i18n } = useTranslation(["prospect", "registration", "translation", "login", "common"]);
  const { countryOptions, getCountryOptions } = useDropdowns();

  const [form, setForm] = useState<RegistrationDTO>({
    invitationLinkId,
    isTPP,
  });
  const [password, setPassword] = useState<string>();
  const [validPassword, setValidPassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [registrationCutoffDate, setRegistrationCutoffDate] = useState<Date>();
  const [registrationCutoffDisplays, setRegistrationCutoffDisplays] = useState<RegistrationCutoffDTO>();

  // Modals & Warnings
  const [showAgeCutoffWarning, setShowAgeCutoffWarning] = useState(false);
  const [showRegistrationPeriodClosedModal, setShowRegistrationPeriodClosedModal] = useState(false);
  const [showDuplicateUsernameModal, setShowDuplicateUsernameModal] = useState(false);
  const [showDuplicateAccountDetectedModal, setShowDuplicateAccountDetectedModal] = useState(false);
  const [showSelfRegisteringTrainerModal, setShowSelfRegisteringTrainerModal] = useState(false);
  const [showCountryCheckModal, setShowCountryCheckModal] = useState(false);
  const [showProspectAlreadyRegisteredModal, setShowProspectAlreadyRegisteredModal] = useState(false);
  const [showInvalidCountryWarningModal, setShowInvalidCountryWarningModal] = useState(false);
  const [showExpiredInvitationModal, setShowExpiredInvitationModal] = useState(false);

  const fetchInvitation = async (_invitationLinkId: string) => {
    try {
      const registrationInfo = await getRegistrationInformation(_invitationLinkId);

      setForm({
        ...form,
        registrationCountryId: registrationInfo.registrationCountryId,
        firstName: registrationInfo.firstName,
        middleName: registrationInfo.middleName,
        lastName: registrationInfo.lastName,
        extLastName: registrationInfo.extLastName,
        isTPP: registrationInfo.isTPP,
      });

      // Change language to prospect's registration country language
      if (registrationInfo.languageId === LanguageIds.ENGLISH) {
        document.documentElement.lang = "en";
        i18n.changeLanguage("en").catch(console.error);
      } else {
        document.documentElement.lang = "es";
        i18n.changeLanguage("es").catch(console.error);
      }
    } catch (e) {
      // @ts-ignore
      if (e.response?.status === 406) {
        setShowProspectAlreadyRegisteredModal(true);
      }
      // @ts-ignore
      else if (e.response?.status === 404) {
        setShowExpiredInvitationModal(true);
      } else {
        addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "Error fetching prospect registration.",
        });
      }
    }
  };

  const ensureOpenRegistrationPeriod = async () => {
    try {
      setLoading(true);
      await checkRegistrationPeriod();
    } catch (e) {
      setShowRegistrationPeriodClosedModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ensureOpenRegistrationPeriod();
  }, []);

  // Copy data from invitation if provided
  useEffect(() => {
    if (invitationLinkId) {
      fetchInvitation(invitationLinkId).catch(console.error);
    }
  }, [invitationLinkId]);

  useEffect(() => {
    let validBirthday;
    let showWarning = false;
    if (form.isTPP) {
      validBirthday = !!form.dateOfBirth?.length;
    } else if (!!form.dateOfBirth?.length && !!registrationCutoffDate) {
      validBirthday = isBefore(parse(form.dateOfBirth, dateFormats.YYYY_MM_DD, new Date()), registrationCutoffDate);
      showWarning = !validBirthday;
    } else {
      validBirthday = false;
    }

    setShowAgeCutoffWarning(showWarning);
    setCanSave(
      !!form.registrationCountryId &&
        !!form.firstName?.length &&
        !!form.lastName?.length &&
        !!form.extLastName?.length &&
        !!form.middleName?.length &&
        !!password?.length &&
        validPassword &&
        validEmail &&
        validBirthday,
    );
  }, [form, password, validPassword, validEmail, registrationCutoffDate]);

  useEffect(() => {
    const countryCheckArray = [CountryIds.USA, CountryIds.CANADA, CountryIds.PR, CountryIds.USVI, CountryIds.GUAM];
    const tppCountryCheckArray: number[] = [CountryIds.COLOMBIA, CountryIds.DR, CountryIds.PANAMA, CountryIds.VEN];

    if (CountryIds.CUBA === form.registrationCountryId) {
      setShowInvalidCountryWarningModal(true);
    } else if (countryCheckArray.includes(form.registrationCountryId)) {
      setShowCountryCheckModal(true);
    } else if (form.isTPP && form.registrationCountryId && !tppCountryCheckArray.includes(form.registrationCountryId)) {
      setShowInvalidCountryWarningModal(true);
    }
  }, [form.isTPP, form.registrationCountryId]);

  const handleChange = (key: keyof RegistrationDTO, value?: number | string) => {
    setForm({ ...form, [key]: value });
  };

  useEffect(() => {
    getRegistrationCutoffDates().then((res: RegistrationCutoffDTO) => {
      const date = new Date(res.registrationCutoff);
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      setRegistrationCutoffDate(new Date(date.getTime() + userTimezoneOffset));
      setRegistrationCutoffDisplays({
        registrationCutoff: formatDate(new Date(date.getTime() + userTimezoneOffset), "mmmm d, yyyy"),
        registrationCutoffSp: res.registrationCutoffSp,
      });
    });
  }, []);

  const checkForDuplicateAccounts = async (): Promise<boolean> => {
    try {
      await validateRegistration(form);
      return false;
    } catch (e) {
      // @ts-ignore
      switch (e.response.status) {
        case 400:
          setShowDuplicateAccountDetectedModal(true);
          break;
        case 406:
          setShowSelfRegisteringTrainerModal(true);
          break;
        case 409:
          setShowDuplicateUsernameModal(true);
          break;
        default:
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: t("common:genericErrorMessage"),
          });
      }
      setLoading(false);
      return true;
    }
  };

  const persistRegistration = async () => {
    setLoading(true);
    const registrationCallback = async (error: Auth0Error, auth0Id: string) => {
      if (!error) {
        await registerProspect({ ...form, auth0Id });
        setAuthenticated(true);
        navigate(RouteConstants.BASE, { replace: true });
      } else {
        addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: t("common:genericErrorMessage"),
        });
      }
      setLoading(false);
    };

    register(form.username, password, registrationCallback);
  };

  const onSubmit = async () => {
    setLoading(true);
    const wasError = await checkForDuplicateAccounts();
    if (!wasError) {
      persistRegistration().catch(console.error);
    }
  };

  const MONTHS: string[] = t("translation:months");
  const dateDropdownPlaceholders: AsSelectPlaceholders = {
    day: t("prospect:day" as const),
    month: t("prospect:month" as const),
    year: t("prospect:year" as const),
  };

  return (
    <>
      <RegistrationPeriodClosedModal show={showRegistrationPeriodClosedModal} />
      <DuplicateAccountDetectedModal
        show={showDuplicateAccountDetectedModal}
        onCancel={() => setShowDuplicateAccountDetectedModal(false)}
        onConfirm={persistRegistration}
      />
      <SelfRegisteringTrainerModal
        show={showSelfRegisteringTrainerModal}
        close={() => setShowSelfRegisteringTrainerModal(false)}
      />
      <AccountAlreadyExistsModal
        show={showDuplicateUsernameModal}
        close={() => setShowDuplicateUsernameModal(false)}
        username={form.username}
      />
      <ProspectAlreadyRegisteredModal show={showProspectAlreadyRegisteredModal} />
      <InvalidRegistrationCountryWarningModal show={showInvalidCountryWarningModal} />
      <ExpiredInvitationModal show={showExpiredInvitationModal} />
      <CountryCheckModal show={showCountryCheckModal} close={() => setShowCountryCheckModal(false)} />
      <Container>
        <UnauthenticatedNavigation />
        <Body isMobile={isMobile}>
          <BackButton onClick={onBack} />
          <div>{t("registration:pleaseProvide")}</div>
          <SubSection header={t("registration:prospectInformation")}>
            <SectionContainer>
              <div>
                <Select
                  id="registration-country"
                  label={t("prospect:registrationCountry")}
                  placeholder={t("translation:selectPlaceholder")}
                  value={getCountryOptions(form.registrationCountryId)}
                  options={countryOptions}
                  onChange={(option: ValueOpt<Country> | undefined) =>
                    handleChange("registrationCountryId", option?.value?.countryId)
                  }
                  required
                />
              </div>
              <div>
                <Input
                  id="first-name"
                  label={t("prospect:firstName")}
                  type="text"
                  value={form.firstName}
                  onChange={(value) => handleChange("firstName", value)}
                  required
                />
              </div>
              <div>
                <Input
                  id="last-name"
                  label={t("prospect:lastName")}
                  type="text"
                  value={form.lastName}
                  onChange={(value) => handleChange("lastName", value)}
                  required
                />
              </div>
              <div>
                <Input
                  id="middle-name"
                  label={t("prospect:middleName")}
                  type="text"
                  showNA
                  value={form.middleName}
                  onChange={(value) => handleChange("middleName", value)}
                  required
                />
              </div>
              <div>
                <Input
                  id="ext-last-name"
                  label={t("prospect:extLastName")}
                  type="text"
                  showNA
                  value={form.extLastName}
                  onChange={(value) => handleChange("extLastName", value)}
                  required
                />
              </div>
              <div>
                <DateInput
                  id="date-of-birth-input"
                  label={t("prospect:birthDate")}
                  invalidDateMessage={t("translation:invalidDate")}
                  asSelects
                  asSelectsPlaceholders={dateDropdownPlaceholders}
                  monthsValueOptions={MONTHS}
                  minDate={DATE_INPUT_MIN_DATE}
                  maxDate={new Date()}
                  value={!!form.dateOfBirth ? parse(form.dateOfBirth, dateFormats.YYYY_MM_DD, new Date()) : undefined}
                  onChange={(value: Date | undefined) => {
                    handleChange("dateOfBirth", !!value ? format(value, dateFormats.YYYY_MM_DD) : undefined);
                  }}
                  required
                />
              </div>
            </SectionContainer>
          </SubSection>
          <SubSection header={t("registration:accountCredentials")}>
            <SectionContainer>
              <div>
                <Email
                  id="email"
                  label={`${t("prospect:emailAddress")} (${t("login:username")})`}
                  value={form.username ? form.username : ""}
                  className="pb-2"
                  setValidEmail={(value: boolean) => setValidEmail(value)}
                  onChange={(value) => handleChange("username", value)}
                  invalidMessage={t("login:invalidEmail")}
                  required
                />
              </div>
              <div>
                <ProspectPasswordInput value={password} setValue={setPassword} setValid={setValidPassword} />
              </div>
            </SectionContainer>
          </SubSection>
          {!canSave && !showAgeCutoffWarning && <WarningText>{t("registration:selfRegWarning")}</WarningText>}
          {showAgeCutoffWarning && (
            <WarningText>
              {t("registration:ageCutoffWarning")}{" "}
              {i18n.language === "es"
                ? registrationCutoffDisplays.registrationCutoffSp
                : registrationCutoffDisplays.registrationCutoff}
              .
            </WarningText>
          )}
          <Button
            disabled={!canSave}
            className="mb-4"
            variant="primary"
            onClick={() => {
              onSubmit().catch(console.error);
            }}
          >
            {t("translation:submit")}
          </Button>
        </Body>
      </Container>
    </>
  );
};

export default RegistrationForm;
