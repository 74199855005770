import { Button, Modal } from "best-common-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import styled from "styled-components";

const EmailText = styled.span`
  color: ${(props) => props.theme.colors["blue"]};
  margin-bottom: 0;
`;

type ProspectAlreadyRegisteredModalProps = {
  show: boolean;
};

const ProspectAlreadyRegisteredModal: React.FC<ProspectAlreadyRegisteredModalProps> = ({ show }) => {
  const { t } = useTranslation(["login", "registration"]);
  const navigate = useNavigate();

  const onConfirm = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("registration:alreadyRegisteredHeader")}</Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          Esta invitación ya ha sido aceptada. Si tienes alguna pregunta, envíe un correo electrónico a:{" "}
          <a href="mailto:registro@mlb.com" style={{ textDecoration: "none" }}>
            <EmailText>registro@mlb.com</EmailText>
          </a>
          .
        </div>

        <div>
          <span>This invitation has already been accepted. If you have any question email: </span>
          <a href="mailto:registration@mlb.com" style={{ textDecoration: "none" }}>
            <EmailText>registration@mlb.com</EmailText>
          </a>
          .
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm} variant="primary">
          {t("login:signIn")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProspectAlreadyRegisteredModal;
