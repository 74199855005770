import React from "react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import { Button, Modal } from "best-common-react";
import { useTranslation } from "react-i18next";

type ExpiredInvitationModalProps = {
  show: boolean;
};

const ExpiredInvitationModal: React.FC<ExpiredInvitationModalProps> = ({ show }) => {
  const { t } = useTranslation(["translation", "registration"]);
  const navigate = useNavigate();

  const onContinue = () => {
    navigate(RouteConstants.BASE);
  };

  return (
    <Modal show={show}>
      <Modal.Header>{t("translation:warning")}</Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          Su invitacion ha expirado, por favor Envíe un correo electrónico a:{" "}
          <a href="mailto:registro@mlb.com">registro@mlb.com</a>.
        </div>
        <div>
          Your invitation has expired, please reach out to:{" "}
          <a href="mailto:registration@mlb.com">registration@mlb.com</a>.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onContinue} variant="primary">
          {t("translation:continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpiredInvitationModal;
